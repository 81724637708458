<template>
    <div>
        <Modal :enable-close="false"
            v-model="mostrarModal"
            :title="'Generación Contratos'"
            :modal-style="{ 'min-width': '400px', 'max-width': '900px', 'height': '400px'}"            
            @before-open="abrirModal"
            @close="cerrarModal">
            <div style="height: 300px; max-height: 450px; overflow-y: auto; overflow-x: hidden; min-width: 400px; max-width: 900px; padding: 5px;">
                <CRow>
                    <CCol>
                        <CSwitch
                            width="150px"
                            class="mx-1 align-middle mb-2"
                            shape="pill"
                            variant="outline"
                            :checked="isDocumentoGeneral"
                            v-bind="{labelOn: 'Si',labelOff: 'No'}"
                            @update:checked="isDocumentoGeneral = !isDocumentoGeneral"
                        /> Documento Contrato general
                    </CCol>
                    
                    <CCol v-if="!isDocumentoGeneral">
                        <CSwitch
                            width="150px"
                            class="mx-1 align-middle mb-2"
                            shape="pill"
                            variant="outline"
                            :checked="asignarGenerarTodos"
                            v-bind="{labelOn: 'Si',labelOff: 'No'}"
                            @update:checked="seleccionarGenerarTodos"
                        /> Generar todos <!--Seleccionar generar documentos a todos-->
                    </CCol>
                    <CCol v-if="!isDocumentoGeneral && !asignarGenerarTodos && mostraExisteConv == 1">
                            <CSwitch
                                width="150px"
                                class="mx-1 align-middle mb-2"
                                shape="pill"
                                variant="outline"
                                :checked="asignarConvertirTodos"
                                v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                @update:checked="seleccionarConvTodos"
                            /> Convertir todos <!--Seleccionar generar documentos a todos-->
                        </CCol>
                </CRow>

                <div v-if="isDocumentoGeneral">

                    <CRow v-for="tipoDoc in tiposEncontradosTemplate" v-bind:key="isDocumentoGeneral.key">
                        <CCol col="4">Utilizar Template para <span class="font-weight-bold">"{{tipoDoc.nombreTipoDoc}} {{ tipoDoc.nombreTipoDoc2 }}"</span></CCol>
                        <CCol col="4">
                            <CSwitch
                                width="150px"
                                class="mx-1 align-middle mb-2"
                                shape="pill"
                                variant="outline"
                                :checked="tipoDoc.generarDocumento"
                                v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                @update:checked="tipoDoc.generarDocumento = !tipoDoc.generarDocumento"
                            />  Generar Documento

                            <CSwitch
                                v-tippy="{ 
                                    placement: 'top',
                                    arrow: true, 
                                    arrowType: 'round', 
                                    animation:'fade',
                                    theme: 'google',
                                    followCursor : false,
                                    interactive : true,
                                    content: `Generar Documento en ${tipoDoc.generarPdf ?'PDF' : 'DOC'}`
                                }"
                                v-show="tipoDoc.generarDocumento"
                                width="150px"
                                class="switchTipoDoc mx-1 align-middle mb-2"
                                shape="pill"
                                variant="outline"
                                :checked="tipoDoc.generarPdf"
                                v-bind="{labelOn: 'PDF',labelOff: 'DOC'}"
                                @update:checked="tipoDoc.generarPdf = !tipoDoc.generarPdf"
                            />
                        </CCol>
                        <CCol col="4" v-show="hayTemplates">
                            <multiselect
                                v-show="tipoDoc.generarDocumento"
                                class="multSelectGesto mb-2"
                                v-model="tipoDoc.templateSelected"
                                selectedLabel=""
                                deselectLabel=""
                                selectLabel=""
                                placeholder="Seleccionar Template" 
                                label="nombreDocumento" 
                                track-by="nombreDocumento"
                                :loading="loadingDocumentosTemplate"
                                :options="documentosTemplate"
                                :multiple="false">
                            </multiselect>
                        </CCol>
                    </CRow>
                </div>
                <CRow v-show="!hayTemplates">
                    <CCol>
                        <span style="color: #0b7689;">* No hay templates para utilizar, se generarán con un template predefinido, si desea agregar un template Ingrese aqui <<<a @click="irGestionarTemplate" style="font-size: 14px;">Gestionar Templates</a>>></span>
                    </CCol>
                </CRow>
                
                <CRow v-show="(isDocumentoGeneral || asignarGenerarTodos) && (mostraExisteDocs == 1 || mostraExisteConv == 1)"> <!--v-show="validarDocsTrabajadores() == 1">-->
                    <CCol col="12" style="color: #0b7689;">
                        <span>* Hay uno o más trabajadores con documentos generados</span>
                    </CCol>
                    <CCol col="12" style="color: #0b7689;">
                        <span v-show="isDocumentoGeneral || asignarGenerarTodos">¿Desea volver a generarlos?</span>
                        <span v-show="isDocumentoGeneral">
                            <CSwitch
                                width="150px"
                                class="mx-1 align-middle mb-2"
                                shape="pill"
                                variant="outline"
                                :checked="generarDocsYaExiste"
                                v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                @update:checked="generarDocsYaExiste = !generarDocsYaExiste"
                            />
                        </span>
                        <span v-show="asignarGenerarTodos">
                            <CSwitch
                                width="150px"
                                class="mx-1 align-middle mb-2"
                                shape="pill"
                                variant="outline"
                                :checked="generarDocsYaExisteTodos"
                                v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                @update:checked="seleccionarDocsYaExisteTodos"
                            />
                        </span>
                        <a style="text-decoration: none; color:#0b7689" class="ml-1"
                            :content="mensajesExisteDocs"
                            v-tippy="{ 
                                placement: 'top',
                                arrow: true, 
                                arrowType: 'round', 
                                animation:'fade',
                                theme: 'google',
                                followCursor : false,
                                interactive : true,
                            }">
                            <span class="pi pi-info-circle" style="font-size: 1rem; color:#0b7689"></span>
                        </a>
                    </CCol>
                    <CCol col="12" style="color: #0b7689;" v-show="mostraExisteConv == 1 && !generarDocsYaExiste && isDocumentoGeneral">
                        <span>¿Desea Convertirlos a PDF?</span>
                        <CSwitch
                            width="150px"
                            class="mx-1 align-middle mb-2"
                            shape="pill"
                            variant="outline"
                            :checked="convertirDocsYaExisteTodos"
                            v-bind="{labelOn: 'Si',labelOff: 'No'}"
                            @update:checked="convertirDocsYaExisteTodos = !convertirDocsYaExisteTodos"
                        />
                        <a style="text-decoration: none; color:#0b7689" class="ml-1"
                            :content="mensajesConvDocs"
                            v-tippy="{ 
                                placement: 'top',
                                arrow: true, 
                                arrowType: 'round', 
                                animation:'fade',
                                theme: 'google',
                                followCursor : false,
                                interactive : true,
                            }">
                            <span class="pi pi-info-circle" style="font-size: 1rem; color:#0b7689"></span>
                        </a>
                    </CCol>
                    <!--<CCol col="12" style="color: #0b7689;">
                        <span v-show="!generarDocsYaExiste || !generarDocsYaExisteTodos">¿Desea Convertirlos a PDF?</span>
                        <span v-show="isDocumentoGeneral && !generarDocsYaExiste">
                            <CSwitch
                                width="150px"
                                class="mx-1 align-middle mb-2"
                                shape="pill"
                                variant="outline"
                                :checked="convertirDocsYaExisteTodos"
                                v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                @update:checked="convertirDocsYaExisteTodos = !convertirDocsYaExisteTodos"
                            />
                        </span>
                        <a style="text-decoration: none; color:#0b7689" class="ml-1"
                            v-show="isDocumentoGeneral && !generarDocsYaExiste"
                            :content="mensajesConvDocs"
                            v-tippy="{ 
                                placement: 'top',
                                arrow: true, 
                                arrowType: 'round', 
                                animation:'fade',
                                theme: 'google',
                                followCursor : false,
                                interactive : true,
                            }">
                            <span class="pi pi-info-circle" style="font-size: 1rem; color:#0b7689"></span>
                
                        </a>
                    </CCol>-->
                </CRow>

                <div v-if="!isDocumentoGeneral">
                    <hr v-show="asignarGenerarTodos">
                    <CRow>
                        <CCol v-show="asignarGenerarTodos">
                            <CSwitch
                                width="150px"
                                class="switchTipoDoc mx-1 align-middle mb-2"
                                shape="pill"
                                variant="outline"
                                :checked="asignarTipoGeneracionTodos"
                                v-bind="{labelOn: 'PDF',labelOff: 'DOC'}"
                                @update:checked="seleccionarTipoGeneracionTodos"
                                v-tippy="{ 
                                    placement: 'top',
                                    arrow: true, 
                                    arrowType: 'round', 
                                    animation:'fade',
                                    theme: 'google',
                                    followCursor : false,
                                    interactive : true,
                                    content: `Generar Documento en ${asignarTipoGeneracionTodos ?'PDF' : 'DOC'}`
                                }"
                            /> {{ `Generar todos en ${asignarTipoGeneracionTodos ?'PDF' : 'DOC'}`}}<!--{{ `Seleccionar generar documentos en ${asignarTipoGeneracionTodos ?'PDF' : 'DOC'} todos`}}-->
                        </CCol>
                        <CCol v-show="asignarGenerarTodos && hayTemplates">
                            <multiselect
                                class="multSelectGestoTC mb-2"
                                v-model="templateSeleccionadoGeneral"
                                selectedLabel=""
                                deselectLabel=""
                                selectLabel=""
                                placeholder="Asignar a todos Template " 
                                label="nombreDocumento" 
                                track-by="nombreDocumento"
                                :loading="loadingDocumentosTemplate"
                                :options="documentosTemplate"
                                :multiple="false"
                                @input="seleccionarTemplateGeneralTodos">
                            </multiselect>
                        </CCol>
                    </CRow>
                    <hr>
                    <div :key="index" v-for="(trab, index) in trabajadores">
                        <CRow>
                            <CCol col="2" class="d-flex align-items-center">
                                <span class="font-weight-bold" style="font-size: 12px;">
                                    ({{ $utils.formatearRut(trab.rutTrabajador) }}) {{ trab.nombreTrabajador }}
                                </span>
                            </CCol>
                            <CCol>
                                <CRow :key="indexDoc" v-for="(doc, indexDoc) in trab.documentos">
                                    <CCol col="3">{{ doc.tipo }} {{ doc.tipoContrato }}</CCol>
                                    
                                    <CCol :col="(doc.generarDocumento && hayTemplates)?'1':'3'" v-if="doc.documento != null">
                                        <span style="color: #0b7689;">
                                            <span>
                                                <tippy 
                                                    class="float-left"
                                                    interactive
                                                    :animate-fill="false"
                                                    placement="bottom"
                                                    distant="7"
                                                    theme="light"
                                                    animation="fade"
                                                    trigger="click"
                                                    arrow>
                                                    <template v-slot:trigger>
                                                        <CIcon :content="$options.freeSet.cilZoomIn"
                                                            v-tippy="{ 
                                                                content: 'Click en el ícono para ver documento',
                                                                placement: 'left',
                                                                arrow: false, 
                                                                arrowType: 'round', 
                                                                animation:'fade',
                                                                theme: 'google',
                                                                followCursor: false,
                                                                interactive: true,
                                                            }"
                                                        />
                                                    </template>
                                                    <span style="width: 300px;">
                                                        <iframe
                                                            :src='doc.documento.viewDocumento'
                                                            width='500px'
                                                            height='500px'
                                                            allow='autoplay'
                                                            ></iframe>
                                                    </span>
                                                </tippy>
                                            </span>
                                            <span v-show="!doc.generarDocumento || !hayTemplates">*Tiene Documento generado</span>

                                            <span v-if="doc.estadoDocumento == '1'">
                                                Este documento se encuentra en revisión no se puede volver a crear
                                            </span>
                                            <span v-else-if="doc.estadoDocumento == '3'">
                                                Este documento se encuentra aceptado no se puede volver a crear
                                            </span>

                                        </span>
                                    </CCol>
                                    <CCol :col="(doc.generarDocumento && hayTemplates)?'5':'6'" v-show="doc.documento != null && (doc.estadoDocumento != '1' || doc.estadoDocumento != '3')"
                                        style="display: flex; justify-content: left; align-items: center;">
                                        <span>Generar Documento</span>
                                        <CSwitch
                                            width="150px"
                                            class="mx-1 align-middle mb-2"
                                            shape="pill"
                                            variant="outline"
                                            :checked="doc.generarDocumento"
                                            v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                            @update:checked="doc.generarDocumento = !doc.generarDocumento"
                                        />
                                        <CSwitch
                                            v-show="doc.generarDocumento"
                                            width="150px"
                                            class="switchTipoDoc mx-1 align-middle mb-2"
                                            shape="pill"
                                            variant="outline"
                                            :checked="doc.generarPdf"
                                            v-bind="{labelOn: 'PDF',labelOff: 'DOC'}"
                                            @update:checked="doc.generarPdf = !doc.generarPdf"
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google',
                                                followCursor : false,
                                                interactive : true,
                                                content: `Generar Documento en ${doc.generarPdf ?'PDF' : 'DOC'}`
                                            }"
                                        />
                                        <span v-show="doc.documento != null && (doc.estadoDocumento != '1' || doc.estadoDocumento != '3') && !doc.generarDocumento && doc.documento.nombreDocumento.split('.')[1].toLowerCase() != 'pdf'">
                                            <CSwitch
                                                width="150px"
                                                class="mx-1 align-middle mb-2"
                                                shape="pill"
                                                variant="outline"
                                                :checked="doc.convertirDocumento"
                                                v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                                @update:checked="doc.convertirDocumento = !doc.convertirDocumento"
                                            />  Convertir
                                        </span>
                                            <CButton v-show="doc.documento != null && (doc.estadoDocumento != '1' || doc.estadoDocumento != '3') && !doc.generarDocumento && doc.documento.nombreDocumento.split('.')[1].toLowerCase() == 'pdf'"
                                                size="sm" shape="pill"
                                                class="ml-2 btnNormalVapp"
                                                @click="volverDocumentoAnterior(doc, trab.idTrabajador)"
                                                :disabled="isGenerando || (doc.volverAnterior && volverAnterior)"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : false,
                                                    interactive : true,
                                                    content: `Volver al anterior 'docx' generado`
                                                }"
                                            >
                                                <CIcon v-if="doc.volverAnterior" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                                Volver a DOC
                                            </CButton>
                                    </CCol>

                                    <CCol col="3" v-show="doc.documento != null && (doc.estadoDocumento != '1' || doc.estadoDocumento != '3') && doc.generarDocumento && hayTemplates">
                                        <multiselect
                                            class="multSelectGestoTC mb-2"
                                            v-model="doc.templateSeleccionado"
                                            selectedLabel=""
                                            deselectLabel=""
                                            selectLabel=""
                                            placeholder="Seleccionar Template" 
                                            label="nombreDocumento" 
                                            track-by="nombreDocumento"
                                            :loading="loadingDocumentosTemplate"
                                            :options="documentosTemplate"
                                            :multiple="false">
                                        </multiselect>
                                    </CCol>


                                    <CCol col="5" v-show="doc.documento == null">
                                        <CSwitch
                                            width="150px"
                                            class="mx-1 align-middle mb-2"
                                            shape="pill"
                                            variant="outline"
                                            :checked="doc.generarDocumento"
                                            v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                            @update:checked="doc.generarDocumento = !doc.generarDocumento"
                                        />  Generar Documento
                                        
                                        <CSwitch
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google',
                                                followCursor : false,
                                                interactive : true,
                                                content: `Generar Documento en ${doc.generarPdf ?'PDF' : 'DOC'}`
                                            }"
                                            v-show="doc.generarDocumento"
                                            width="150px"
                                            class="switchTipoDoc mx-1 align-middle mb-2"
                                            shape="pill"
                                            variant="outline"
                                            :checked="doc.generarPdf"
                                            v-bind="{labelOn: 'PDF',labelOff: 'DOC'}"
                                            @update:checked="doc.generarPdf = !doc.generarPdf"
                                        />
                                    </CCol>
                                    <CCol col="4" v-show="doc.documento == null">
                                        <span v-show="doc.documento == null && doc.generarDocumento && hayTemplates">
                                            <multiselect
                                                class="multSelectGestoTC mb-2"
                                                v-model="doc.templateSeleccionado"
                                                selectedLabel=""
                                                deselectLabel=""
                                                selectLabel=""
                                                placeholder="Seleccionar Template" 
                                                label="nombreDocumento" 
                                                track-by="nombreDocumento"
                                                :loading="loadingDocumentosTemplate"
                                                :options="documentosTemplate"
                                                :multiple="false">
                                            </multiselect>
                                        </span>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        <hr>
                    </div>
                </div>
            </div>
            
            <div class="vm-footer">
                <CRow>
                    <CCol>
                        <CButton
                            class="btnPrincipalVapp float-left"
                            @click="generarDocumentosContrato()"
                            :disabled="isGenerando || volverAnterior"
                        >
                            <CIcon v-if="isGenerando" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                            Generar Documentos
                        </CButton>
                    </CCol>
                    <CCol>
                        <CButton
                            class="btnPrincipalVapp float-right"
                            @click="cerrarModal"
                        >Cerrar</CButton>
                    </CCol>
                </CRow>
            </div>
        </Modal>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
    import cons from "@/const";
    import axios from "axios";
    import apiUrls from '@/views/utils/apiUrls'
    import { format, formatDistance, formatRelative, subDays } from 'date-fns'
    import es from 'date-fns/locale/es'

    import { freeSet } from "@coreui/icons";
    import Multiselect from 'vue-multiselect'
    
    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    import DatePicker from 'vue2-datepicker';

    import CryptoJS from 'crypto-js';

    import bcrypt from 'bcryptjs';

    export default {
        name: "ModalGeneracionContrato",
        freeSet,
        props: {
            mostrarModal: Boolean,
            empresaSelected: Object,
            trabajadores: Array,
            //causalesFnq: Object,
            mostrarPeriodo: Boolean,
            tipoDocumentoSelected: Object,
            mesSelected: Object,
            periodoSelected: Object


        },
        components: {
            'Modal': VueModal,
            Multiselect,
            DatePicker,
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port+"://" + cons.ipServer,
                
                langFecha: cons.langPicker,
                
                isGenerando: false,

                isDocumentoGeneral: true,
                causalGeneral: "",
                fechaTerminoGeneral: null,
                causalSelected: null,
                causalesFnq: {
                    "isLoading": false,
                    "causales": []
                },

                loadingDocumentosTemplate: false,
                generarDocumento: false,
                hayTemplates: false,
                documentosTemplate: [],
                templateSelected: null,
                generarDocsYaExiste: false,

                utilizarTemplateGeneral: true,
                tiposEncontradosTemplate: [],

                mostraExisteDocs: 0,
                mostraExisteConv: 0,
                mensajesExisteDocs: "",
                mensajesConvDocs: "",

                ejecutados: 0,

                asignarGenerarTodos: false,
                asignarTipoGeneracionTodos: false,
                templateSeleccionadoGeneral: null,

                generarDocsYaExisteTodos: true,
                convertirDocsYaExisteTodos: false,

                asignarConvertirTodos: false,

                volverAnterior: false,


            };
        },
        created: function () {},
        beforeMount() {},
        methods: {
            cerrarModal: function(){
                //this.mostrarModalSubirDoc = false
                this.isDocumentoGeneral = true
                this.asignarGenerarTodos = false
                this.asignarTipoGeneracionTodos = false
                this.templateSeleccionadoGeneral = null

                this.generarDocsYaExisteTodos = true
                this.convertirDocsYaExisteTodos = false

                this.asignarConvertirTodos = false
                this.tiposEncontradosTemplate = []

                this.$emit('cerrarModal', false)

            },
            abrirModal: function(){
                this.getDocumentosTemplate()
                let trabEncontrados = this.validarDocsTrabajadores()

                this.mostraExisteDocs = trabEncontrados.trabEncontrado.length > 0 ? 1 : 0
                this.mostraExisteConv = trabEncontrados.trabEncontradoConv.length > 0 ? 1 : 0
                //this.getCausalesFiniquitos()
                //this.comprobarDocumentos()
            },

            /**---------------------------------------------- */
            seleccionarDocsYaExisteTodos: function(){
                this.generarDocsYaExisteTodos = !this.generarDocsYaExisteTodos
                this.trabajadores.forEach(trabajador => {
                    trabajador.documentos.forEach(docTrab => {
                        if(docTrab.estadoDocumento != '1' || docTrab.estadoDocumento != '3'){
                            if(docTrab.documento != null){
                                docTrab.generarDocumento = this.generarDocsYaExisteTodos
                            }
                        }
                    })
                })
            },
            seleccionarTemplateGeneralTodos: function(){
                this.trabajadores.forEach(trabajador => {
                    trabajador.documentos.forEach(docTrab => {
                        if(docTrab.estadoDocumento != '1' || docTrab.estadoDocumento != '3'){
                            docTrab.templateSeleccionado = this.templateSeleccionadoGeneral
                        }
                    })
                })

            },
            seleccionarTipoGeneracionTodos: function(){
                this.asignarTipoGeneracionTodos = !this.asignarTipoGeneracionTodos
                this.trabajadores.forEach(trabajador => {
                    trabajador.documentos.forEach(docTrab => {
                        if(docTrab.estadoDocumento != '1' || docTrab.estadoDocumento != '3'){
                            docTrab.generarPdf = this.asignarTipoGeneracionTodos
                        }
                    })
                })
            },
            seleccionarGenerarTodos: function(){
                this.asignarGenerarTodos = !this.asignarGenerarTodos
                this.trabajadores.forEach(trabajador => {
                    trabajador.documentos.forEach(docTrab => {
                        if((docTrab.estadoDocumento != '1' || docTrab.estadoDocumento != '3')){
                            docTrab.generarDocumento = this.asignarGenerarTodos
                        }
                    })
                })
            },
            seleccionarConvTodos: function(){
                this.asignarConvertirTodos = !this.asignarConvertirTodos
                this.trabajadores.forEach(trabajador => {
                    trabajador.documentos.forEach(docTrab => {
                        if((docTrab.estadoDocumento != '1' || docTrab.estadoDocumento != '3') && docTrab.documento != null && docTrab.documento.nombreDocumento.split('.')[1].toLowerCase() != 'pdf'){
                            docTrab.convertirDocumento = this.asignarConvertirTodos
                        }
                    })
                })
            },
            getDocumentosTemplate: function(){
                if(this.tipoDocumentoSelected != null && this.empresaSelected != null){
                    this.loadingDocumentosTemplate = true
                    let url =  this.urlBase + "/get_documentos_template/"
                    axios({
                        method: "POST", 
                        "url": url,
                        "data": {
                            "filtrarPorPeriodo": this.mostrarPeriodo,
                            "idEmpresa": this.empresaSelected.idEmpresa,
                            "idTipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.idTipoDocumento : 'null',
                            "mes": (this.mesSelected != null) ? this.mesSelected.valor : null,
                            "anio": (this.periodoSelected != null) ? this.periodoSelected.valor : null
                        },
                        "headers": {
                            'Authorization': `${this.tokenLogin}`
                        }
                    }).then(result => {
                        this.documentosTemplate = result.data.templateDocs
                        if(this.documentosTemplate.length>0){
                            this.hayTemplates = true
                        }
                        if(this.documentosTemplate.length==1){
                            this.templateSelected = this.documentosTemplate[0]
                        }

                        this.loadingDocumentosTemplate = false
                        
                        //let encryptedPassword = await bcrypt.hash(JSON.stringify(this.templateSelected), 10);
                        
                    }, error => {
                        this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                        this.loadingDocumentosTemplate = false
                    })
                    .finally(() => {
                        this.comprobarDocumentos()
                    });
                }
            },

            comprobarDocumentos: function(){
                //this.tiposEncontradosTemplate = []
                let key = 1
                this.trabajadores.forEach(trabajador => {
                    trabajador.documentos.forEach(doc =>{
                        if(trabajador.tiposCtrEncontrados.includes(`${doc.tipo}_${doc.tipoContrato}`) && !this.tiposEncontradosTemplate.some(tipo => tipo.nombreTipoDoc === doc.tipo && tipo.nombreTipoDoc2 === doc.tipoContrato)){//!this.tiposEncontradosTemplate.includes(doc.tipo)){
                            let td = {
                                "key": key,
                                "nombreTipoDoc": doc.tipo,
                                "nombreTipoDoc2": doc.tipoContrato,
                                "templateSelected": null,
                                "generarDocumento": false,
                                "generarPdf": false
                            }
                            trabajador["tiposEncontradosTemplate"].push(td)
                            this.tiposEncontradosTemplate.push(td)
                            key += 1
                        }
                    })

                });
            },
            validarContratoTrabajadores: function(){
                let hayConTermino = false
                let totalConTermino = 0
                if(this.empresaSelected != null){
                    this.trabajadores.forEach(trabajador => {
                        if(trabajador.trabajadorFiniquito){
                            hayConTermino = true
                            totalConTermino += 1
                        }
                    })
                    
                    if(totalConTermino == this.trabajadores.length){
                        return 1
                    }
                    else if(totalConTermino > 0){
                        return 2
                    }
                }
            },
            
            irGestionarTemplate: function(){

                let data = {
                    "idEmpresa": this.empresaSelected.idEmpresa, 
                    "idDocSelec": this.tipoDocumentoSelected.idTipoDocumento,
                    "mostrarPeriodo": this.mostrarPeriodo
                }
                if(this.mostrarPeriodo){
                    data["anioPeriodo"] = (this.periodoSelected != null) ? this.periodoSelected.valor : null
                    data["mesPeriodo"] = (this.mesSelected != null) ? this.mesSelected.valor : null
                }
                
                let routeData = this.$router.resolve({path: 'templates_documentos', query: data});
                window.open(routeData.href, '_blank');
            },
            cambiarEstadoGenerandoTrabajadores: function(estado){
                this.trabajadores.forEach(trabajador =>{
                    trabajador.documentos.forEach(docTrab => {
                        if(docTrab.generarDocumento){
                            trabajador.isLoadingGenerarDocumento = estado
                            docTrab.isGenerando = estado
                        }

                    })
                })
            },
            volverDocumentoAnterior: function(doc, idTrabajador){
                let url = `${this.urlBase}${apiUrls.remuneraciones.contratoFiniquito.volverDocumentoAnterior}`
                this.volverAnterior = true
                doc.volverAnterior = true
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "idEmpresa": this.empresaSelected.idEmpresa,
                        "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.nombreTipoDocumento : 'null',
                        "mes": (this.mesSelected != null) ? this.mesSelected.valor : 'null',
                        "anio": (this.periodoSelected != null) ? this.periodoSelected.valor : 'null',
                        "filtrarPorPeriodo": this.mostrarPeriodo,
                        "idDocumento": doc.documento.idDocumento,
                        "idCtrDoc": doc.idDocumentoCtro,
                        "idTrabajador": idTrabajador,
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.mostrarNotificaciones(1, result.data.mensajeGeneral)
                    doc.documento = result.data.documento
                }, 
                error => {
                    this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                })
                .finally(() => {
                    this.volverAnterior = false
                    doc.volverAnterior = false
                });
            },
            generarDocumentosContrato: function(){

                let seleccionados = []
                let permiteGenerar = true

                if(this.isDocumentoGeneral){
                    let hayGenerar = false
                    if(this.convertirDocsYaExisteTodos){
                        hayGenerar = true
                    } else {
                        this.tiposEncontradosTemplate.forEach(tipoDoc => {
                            if(tipoDoc.generarDocumento){
                                hayGenerar = true
                                if(this.hayTemplates && tipoDoc.templateSelected == null){
                                    this.mostrarNotificaciones(2, `No ha seleccionado un template para "${tipoDoc.nombreTipoDoc}"`)
                                    permiteGenerar = false
                                }else{
                                    permiteGenerar = true
                                }
                            }
                        })
                    }
                    if(!hayGenerar){
                        this.mostrarNotificaciones(2, `No se ha seleccionado documentos para generar`)
                        permiteGenerar = false
                    }

                    if(permiteGenerar){
                        this.trabajadores.forEach(trabajador => {
                            let docsGenerar = []
                            trabajador.documentos.forEach(docTrab => {
                                if(docTrab.estadoDocumento == '1' || docTrab.estadoDocumento == '3'){
                                    return
                                }
                                if(this.convertirDocsYaExisteTodos){
                                    if(docTrab.documento != null && docTrab.documento.nombreDocumento.split('.')[1].toLowerCase() != 'pdf'){
                                        docsGenerar.push({
                                            "idDocumentoCtro": docTrab.idDocumentoCtro,
                                            "idDocumento": docTrab.documento.idDocumento,
                                            "generarDocumento": false,
                                            "convertirDocumento": true,
                                        })
                                    }
                                } else{
                                    
                                    let tipoDocGeneral = this.tiposEncontradosTemplate.find(tipo => tipo.nombreTipoDoc === docTrab.tipo &&tipo.nombreTipoDoc2 === docTrab.tipoContrato)
                                    
                                    if(tipoDocGeneral.generarDocumento){
                                        let idTemplate = null

                                        if(this.hayTemplates){
                                            if(tipoDocGeneral.templateSelected != null){
                                                idTemplate = tipoDocGeneral.templateSelected.idTemplateDocumento
                                                docsGenerar.push({
                                                    "idDocumentoCtro": docTrab.idDocumentoCtro,
                                                    "idTemplateSeleccionado": tipoDocGeneral.templateSelected.idTemplateDocumento
                                                })
                                            }
                                        } 
                                        docsGenerar.push({
                                            "idDocumentoCtro": docTrab.idDocumentoCtro,
                                            "idTemplateSeleccionado": idTemplate
                                        })
                                    }

                                }
                                
                                
                            })
                            if(docsGenerar.length > 0){
                                seleccionados.push({
                                    "idTrabajador": trabajador.idTrabajador, 
                                    "rutTrabajador": trabajador.rutTrabajador,
                                    "tieneDocumento": trabajador.hayDocumentos,
                                    "docsGenerar": docsGenerar
                                })
                            }
                        })
                    }
                } else {
                    this.trabajadores.forEach(trabajador => {
                        let docsGenerar = []
                        trabajador.documentos.forEach(docTrab => {
                            if(docTrab.generarDocumento){
                                let idTemplate = null
                                if(this.hayTemplates){
                                    if(docTrab.templateSeleccionado == null){
                                        this.mostrarNotificaciones(2, `No ha seleccionado un template para "${docTrab.tipo}" del trabajador ${trabajador.nombreTrabajador}`)
                                        permiteGenerar = false
                                    } else{
                                        idTemplate = docTrab.templateSeleccionado.idTemplateDocumento
                                    }
                                }
                                docsGenerar.push({
                                    "idDocumentoCtro": docTrab.idDocumentoCtro,
                                    "idTemplateSeleccionado": idTemplate,//docTrab.templateSeleccionado.idTemplateDocumento,
                                    "generarPdf": docTrab.generarPdf,
                                    "generarDocumento": docTrab.generarDocumento,
                                    "convertirDocumento": docTrab.convertirDocumento,
                                })
                            } else if(docTrab.convertirDocumento){
                                docsGenerar.push({
                                    "idDocumentoCtro": docTrab.idDocumentoCtro,
                                    "idDocumento": docTrab.documento.idDocumento,
                                    "generarDocumento": docTrab.generarDocumento,
                                    "convertirDocumento": docTrab.convertirDocumento,
                                })
                            }
                        })
                        if(permiteGenerar && docsGenerar.length > 0){
                            seleccionados.push({
                                "idTrabajador": trabajador.idTrabajador, 
                                "rutTrabajador": trabajador.rutTrabajador,
                                "tieneDocumento": trabajador.hayDocumentos,
                                "docsGenerar": docsGenerar
                            })
                        }
                    })

                }

                if(permiteGenerar){
                    
                    this.isGenerando = true
                    this.$emit('generandoDocumento', true)
                    this.cambiarEstadoGenerandoTrabajadores(true)

                    if(seleccionados.length<=0){
                        this.mostrarNotificaciones(2, `No se ha seleccionado ningun documento para generar`)
                        this.isGenerando = false
                        this.$emit('generandoDocumento', false)
                        this.cambiarEstadoGenerandoTrabajadores(false)
                        return
                    }

                    let url = `${this.urlBase}${apiUrls.remuneraciones.contratoFiniquito.generarDocumentosCtroTmpl}`

                    axios({
                        method: "POST", 
                        "url": url,
                        "data": {
                            "idEmpresa": this.empresaSelected.idEmpresa,
                            "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.nombreTipoDocumento : 'null',
                            "mes": (this.mesSelected != null) ? this.mesSelected.valor : 'null',
                            "anio": (this.periodoSelected != null) ? this.periodoSelected.valor : 'null',
                            "filtrarPorPeriodo": this.mostrarPeriodo,
                            "isDocumentoGeneral": this.isDocumentoGeneral,
                            "hayTemplates": this.hayTemplates,
                            "tiposEncontrados": this.tiposEncontradosTemplate,
                            "trabajadoresGeneracion": seleccionados,
                            "datosGeneracion": "hashTrab",
                            "generarDocsYaExiste": this.generarDocsYaExiste,
                            "convertirGeneral": this.convertirDocsYaExisteTodos,
                        },
                        "headers": {
                            'Authorization': `${this.tokenLogin}`
                        }
                    }).then(result => {
                        this.isGenerando = false

                        this.mostrarNotificaciones(1, result.data.mensajeGeneral)
                        
                        this.$emit('obtenerTrabajadores')
                        
                        this.cerrarModal()
                    }, 
                    error => {
                        this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                        this.isGenerando = false
                    })
                    .finally(() => {
                        this.isGenerando = false
                        this.$emit('generandoDocumento', false)
                        this.cambiarEstadoGenerandoTrabajadores(false)
                    });
                }


            },
            verMensajeDocsGenerar: function(docsVolverGenerar){
                
                let mensaje = "Trabajador(es)"
                let mensajes = []
                let total = 0

                docsVolverGenerar.forEach(trabsDocs => {
                    let docsTrab = []
                    trabsDocs.documentos.forEach(doc => {docsTrab.push(`${doc.tipo} ${doc.tipoContrato}`)})
                        if(total<5){
                            mensajes.push(`<div>Los documentos ${docsTrab.join(", ")} del trabajador ${trabsDocs.rutTrabajador}`)                            
                        }
                        total += 1

                })
                let mensajeMas = total>5?` y otros ${total-mensajes.length}` : ''
                this.mensajesExisteDocs = `<div>${mensajes.join(", </div>")}${mensajeMas}</div> ya tienen documento generado`
            },
            verMensajeDocsConvertir: function(docsConvertir){
                
                let mensaje = "Trabajador(es)"
                let mensajes = []
                let total = 0

                docsConvertir.forEach(trabsDocs => {
                    let docsTrab = []
                    trabsDocs.documentos.forEach(doc => {docsTrab.push(`${doc.tipo} ${doc.tipoContrato}`)})
                        if(total<5){
                            mensajes.push(`<div>Los documentos ${docsTrab.join(", ")} del trabajador ${trabsDocs.rutTrabajador}`)                            
                        }
                        total += 1

                })
                let mensajeMas = total>5?` y otros ${total-mensajes.length}` : ''
                this.mensajesConvDocs = `<div>${mensajes.join(", </div>")}${mensajeMas}</div> se pueden convertir`
            },
            validarDocsTrabajadores: function(){
                if(this.mostrarModal){

                    let trabEncontrado = []
                    let trabEncontrado2 = []

                    this.trabajadores.forEach(trab => {
                        let tieneDocumentoValido = false;
                        let tieneDocumentoNoPdf = false;

                        trab.documentos.forEach(doc => {
                            if (doc.documento != null && (doc.estadoDocumento != "1" || doc.estadoDocumento != "3")) {
                                tieneDocumentoValido = true;
                                if (doc.documento.nombreDocumento.split('.')[1].toLowerCase() != 'pdf') {
                                    tieneDocumentoNoPdf = true;
                                }
                            }
                        });

                        if (tieneDocumentoValido) {
                            trabEncontrado.push(trab);
                        }
                        if (tieneDocumentoNoPdf) {
                            trabEncontrado2.push(trab);
                        }
                    });


                    //this.docsVolverGenerar = trabEncontrado
                    if(trabEncontrado.length>0){
                        this.verMensajeDocsGenerar(trabEncontrado)
                    }
                    if(trabEncontrado2.length > 0){
                        this.verMensajeDocsConvertir(trabEncontrado2)
                    }

                    return {
                        "trabEncontrado": trabEncontrado,
                        "trabEncontradoConv": trabEncontrado2
                    }
                }
            }
            

        },
    };
</script>

<style>
    /*.switchTipoDoc .c-switch-slider{
        color: #29235C;
    }
    .switchTipoDoc .c-switch-slider::before {
        color: #29235C;
    }*/
    .switchTipoDoc .c-switch-input:checked ~ .c-switch-slider::after {
        color: #29235C;
    }
    .switchTipoDoc .c-switch-input:checked + .c-switch-slider {
        /*pildora */
        border-color: #29235C;
    }
    .switchTipoDoc .c-switch-input + .c-switch-slider {
        /*pildora */
        border-color: #29235C;
    }
    /*
    .switchTipoDoc .c-switch-slider::after {
        color: #d8dbe0;
    }
    .switchTipoDoc .c-switch-slider::before {
        color: red;
    }*/
    
    .switchTipoDoc .c-switch-input:checked + .c-switch-slider::before {
        /*circulo del switch*/
        border-color: #d8dbe0;
        background-color: #fff;
    }
    .switchTipoDoc .c-switch-input:checked + .c-switch-slider::after {
        border-color: #d8dbe0;
        background-color: #fff;
    }

    /*.switchTipoDoc .c-switch-input:checked ~ .c-switch-slider::before {
        color: #29235C;
    }*/
    .switchTipoDoc .c-switch-input:checked ~ .c-switch-slider::after {
        color: #29235C;
        font-size: 0.6rem;
        font-weight:normal;
    }
    .switchTipoDoc .c-switch-input ~ .c-switch-slider::after {
        color: #29235C;
        font-size: 0.6rem;
        font-weight:normal;
    }

    .tippy-popper .tippy-tooltip .tippy-content{
        width: 100%;
        max-width: 100%;
    }
    
</style>